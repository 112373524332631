import { Box, CircularProgress } from '@mui/material'
import React, { ReactNode, Suspense } from 'react'

type Props = {
    element: ReactNode 
}
const SuspenseWrapper = (props: Props) => {
    return (
        <Suspense fallback={<SuspenseLoader />}>
            {props.element}
        </Suspense>
    )
}

const SuspenseLoader = () => (
    <Box>
        <CircularProgress />
    </Box>
)

export default SuspenseWrapper