export {
    useQuery,
    useQueries,
    useMutation,
    useQueryClient
} from "react-query"

export const REACT_QUERY_KEYS = {
    getPriorityTaskList: "getPriorityTaskList",
    getInvoicesList: "getInvoicesList",
    getClientInvoicesList: "getCLientInvoicesList",
    getClientSearchList: "getClientSearchList",
    getUsersList: "getUsersList",
    getUserDetails: "getUserDetails",
    getTodoList: "getTodoList",
    getComplettedTodo: "getCompletedQuery",
    getDscInventoryList: "getDscInventoryList",
    getDocumentInventoryList: "getDocumentInventoryList",
    getGivenOutDocsList: "getGivenOutDocsList",
    getInventoryDetails: "getInventoryDetails",
    getAllUsers: "getAllUsers",
    getAllAudits: "getAllAudits",
    getAllClients: "getAllClients",
    getAllTasksFromConfig: "getAllTasksFromConfig",
    getTasksRegisteredForAutomation: "getTasksRegisteredForAutomation",
    getBilledTasks: "getBilledTasks",
    getPrimaryTaskList: "getPrimaryTaskList",
    getTaskStatusList: "getTaskStatusList",
    getTaskDocTypes: "getTaskDocTypes",
    getTaskAttachments: "getTaskAttachments",
    getTaskChats: "getTaskChats",
    getCompletedTasks: "getCompletedTasks",
    getActiveTasksOfClient: "getActiveTasksOfClient",
    getSystemTaskNames: "getSystemTaskNames",
    getAllSubTasks: "getAllSubTasks",
    getSnoozedTask: "getSnoozedTasks",
    getClientinventoryRegistry: "getClientinventoryRegistry",
    getClientDocumentsInventoryList: "getClientDocumentsInventoryList",
    getHsnSacDetails: "getHsnSacDetails",
    getNonBilledTasks: "getNonBilledTasks",
    getAllPanNumbers: "getAllPanNumbers",
    getLeaveHistory: "getLeaveHistory",
    getOrganizationLeaveRequests: "getOrganizationLeaveRequests",
    getUserWorkLog: "getUserWorkLog",
    getAllWorkLogs: "getAllWorkLogs",
    getDashboardData: "getDashboardData",
    getTaskConfiguration: "getTaskConfiguration",
    getFinancialTaskDetails: "getFinancialTaskDetails",
    getGeneratedPeriods: "getGeneratedPeriods",
    getTaskPeriodConfig: "getTaskPeriodConfig",
    getUserDesignationList: "getUserDesignationList",
    getLoggedInUserInfo:"getLoggedInUserInfo",
    getClientInfo: "getClientInfo",
    getPrimaryTaskAnalytics:"getPrimaryTaskAnalytics",
    getAnalyticsQueryOptions:"getAnalyticsQueryOptions",
    getCompletedTasksAnalyticsQueryOptions:"getCompletedTasksAnalyticsQueryOptions",
    getCompletedTaskAnalytics:"getCompletedTaskAnalytics",
    getTaskCategoryFiles:"getTaskCategoryFiles"
}