import { Auth } from "aws-amplify";
import axios, { AxiosInstance, AxiosResponse } from "axios";
import { getEnv } from "../internal/utils/env";
interface ICallApi {
  relativePath: string;
  method: string;
  data?: any;
}


export function getBaseUrl(){
  let url
  const protocol = window.location.protocol;
  if (window.location.hostname !== 'localhost') {
    url=`${protocol}//${window.location.hostname}/v1`
  } else {
    url=`${protocol}//${window.location.hostname}:8000/v1`
  }
  return url
}

export async function callApi(params: ICallApi) {
  const { data, method, relativePath } = params;
  const session = await Auth.currentSession()
  const accessToken = session.getAccessToken().getJwtToken()
  const idToken = session.getIdToken().getJwtToken()
  const env = getEnv()

  const result = await axios({
    url: `${getBaseUrl()}${relativePath}`,
    method,
    data,
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'idToken': `Bearer ${idToken}`
    }
  });
  return result as AxiosResponse;

}

export class ApiService {
  private axios: AxiosInstance
  constructor() {
    this.initializeTokens = this.initializeTokens.bind(this)
    this.axios = axios
    this.initializeTokens()
    this.get = this.get.bind(this)
    this.post = this.post.bind(this)
    this.patch = this.patch.bind(this)
    this.delete = this.delete.bind(this)
    this.put = this.put.bind(this)
    this.clearTokens = this.clearTokens.bind(this)
  }

  async initializeTokens() {
    try {
      const session = await Auth.currentSession()
      const accessToken = session.getAccessToken().getJwtToken()
      const idToken = session.getIdToken().getJwtToken()
      this.axios = axios.create({
        baseURL: getBaseUrl(),
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'idToken': `Bearer ${idToken}`
        }
      })
    } catch (err) {
      console.log(err)
    }


  }

  async get(url: string) {
    await this.initializeTokens()
    const response = await this.axios.get(url)
    return response as AxiosResponse
  }

  async post(url: string, data: any, options?: object) {
    await this.initializeTokens()
    const response = await this.axios.post(url, data, {
      ...options
    })
    return response
  }

  async patch(url: string, data: object) {
    await this.initializeTokens()
    const response = await this.axios.patch(url, data)
    return response
  }
  async delete(url: string, data?: Record<string, any>) {
    await this.initializeTokens()
    const response = await this.axios.delete(url, { data })
    return response
  }
  async put(url: string, data: object) {
    await this.initializeTokens()
    const response = await this.axios.put(url, data)
    return response
  }

  async clearTokens() {
    this.axios.interceptors.request.clear()
  }

}


export const api = new ApiService()
