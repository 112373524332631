import React, { createContext, useCallback, useContext } from "react";
import { useQuery } from "react-query";
import { getAllSystemUsers } from "../apiService/users";
import { REACT_QUERY_KEYS } from "../internal/react-query";

type User = {
  name: string;
  email: string;
  [key: string]: any;
};
type Mapper = { from: "email" | "name"; to: "email" | "name" };

type ApiContext = {
  users: User[];
  getUserInfoMapper: (params: Mapper) => Map<string, string>;
};

const ApiStoreContext = createContext<ApiContext>({
  users: [],
  getUserInfoMapper: () => new Map(),
});

export const ApiContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
    const getAllUsersQuery = useQuery(REACT_QUERY_KEYS.getAllUsers, async () => {
        return await getAllSystemUsers();
    }, {
        retry:2,
        refetchOnMount: true,
        refetchOnReconnect: true,
        refetchOnWindowFocus: true,
    });
    const getUserInfoMapper = useCallback(({ from, to }: Mapper) => {
        const users = getAllUsersQuery.data?.data as User[] || [];
        const mapper = new Map<string, string>();
        for (const user of users) {
              mapper.set(user[from], user[to])
        }
        return mapper
    }, [getAllUsersQuery.status, getAllUsersQuery.data, getAllUsersQuery.isFetching, getAllUsersQuery.isLoading])

  return (
    <ApiStoreContext.Provider
      value={{
        users: getAllUsersQuery.data?.data as User[],
        getUserInfoMapper,
      }}
    >
      {children}
    </ApiStoreContext.Provider>
  );
};

export const useApiStore = () => useContext(ApiStoreContext);
