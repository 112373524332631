import { Auth } from "aws-amplify"
import { RELATIVE_PATHS } from "../constants/api"
import { ApiService } from "./apiService"
import { getQueryObject } from "./utils"
import { getEnv } from "../internal/utils/env"

const api = new ApiService()
const relativePath = RELATIVE_PATHS.users
// Types
type UserRegistrationInfo = {
    firstName: string | undefined
    lastName: string | undefined
    email: string | undefined
    name: string | undefined,
    phone: string | undefined
    empId: string
}

type UserOnBoardingParams = {
    name: string,
    email: string,
    phoneNumber: string,
    roles: string,
    designation: string,
    branch: string,
    lineManager: string,
    profilePic?: string,
    file?: any,
    qualification?: string,
    lineManagerEmail?:string
}

/**
 * 
 * @returns All system users
 */
export async function getAllUsers() {
    const response = await api.get(`${relativePath}?isActive=true`)
    return response
}

export async function getAllSystemUsers() {
    
    const response = await api.get(`${relativePath}`)
    return response
}

/**
 * 
 * @returns All active users in the system with flag isActive = true
 */
export async function getActiveUsers() {
    const response = await api.get(`${relativePath}?isActive=true`)
    return response
}


export async function searchUser(query: any) {
    const { params, value } = getQueryObject(query)
    const response = await api.get(`${relativePath}?${params}=${value}&isSubString=true`)
    return response
}





/**
 * 
 * @param email Email id of an existing user
 * @returns User informortion
 */
export async function getUserFromDb(email: string) {
    const response = await api.get(`${relativePath}?email=${email}`)
    return response
}


/**
 * 
 * @returns - List of user roles from configuration
 */

export async function getUserRoles() {
    const response = await api.get("/organization/configurations?configKey=userRoles&onlyNeedActive=true")
    return response
}

/**
 * 
 * @returns - List of user designations from the configuration
 */
export async function getUserDesignations() {
    const response = await api.get("/organization/configurations?configKey=userDesignations&onlyNeedActive=true")
    return response
}

/**
 * 
 * @returns - List of branches of the organization
 */
export async function getBranches() {
    const env = getEnv()
    const organisation_id = env.WEB_CLIENT_ID;
    const response = await api.get(`/organisation?organisation_id=${organisation_id}`)
    return response
}

/**
 * 
 * @param userInfo - details for registering new user
 */
export async function registerUser(userInfo: UserRegistrationInfo) {
    const response = await api.post("organization/admin/newCognitoUser", userInfo)
    return response
}

/**
 * 
 * @param email
 * @param isActive
 * @returns Array of users
 */
export async function deactivateActivateUser({ email, isActive }: { email: string, isActive: boolean }) {
    const response = await api.patch(`${relativePath}?email=${email}`, { isActive })
    return response
}

/**
 * 
 * @param data - User information for inboarding into the system
 * @returns AxiosResponse
 */
export async function onBoardUser(data: UserOnBoardingParams) {
    const response = await api.post(relativePath, data)
    return response
}

/**
 * 
 * @param data  Edited fields
 * @returns  AxiosResponse
 */
export async function updateUserInformation(data: Partial<UserOnBoardingParams> | Record<string, string | undefined | null>){
    const response = await api.patch(`${relativePath}?email=${data.email}`, data)
    return response
}

/**
 * 
 * @param email Email id entered in text field
 * @returns - List of users from cognito
 */
export async function getCognitoUser(email:string){
    const response = await api.get(`/organization/newUser?email=${email}`)
    return response
}

export async function getCurrentUserFromDb(){
    const currentUser = await Auth.currentUserInfo()
    const user = await getUserFromDb(currentUser.attributes.email)
    return user.data
}

export async function getLoggedInCogitoUser(){
    const currentUser = await Auth.currentUserInfo()
    return currentUser
}