export const getEnv = () => {
    const stringifiedEnvs = sessionStorage.getItem("n-oms-env-store")
   
    const parsedEnvs = JSON.parse(stringifiedEnvs!)
    return {
        TASK_FILE_VERSIONING_ENABLED: parsedEnvs.TASK_FILE_VERSIONING_ENABLED,
        COGNITO_POOL_ID: parsedEnvs.COGNITO_POOL_ID,
        IDENTITY_POOL_ID: parsedEnvs.IDENTITY_POOL_ID,
        AWS_REGION: parsedEnvs.AWS_REGION,
        WEB_CLIENT_ID: parsedEnvs.WEB_CLIENT_ID,
        S3_BUCKET_NAME: parsedEnvs.S3_BUCKET_NAME,
        API_URL: parsedEnvs.API_URL,
        REACT_APP_CLIENT_PORTAL_API_URL: parsedEnvs.REACT_APP_CLIENT_PORTAL_API_URL,
        REACT_APP_BASE_URL: parsedEnvs.REACT_APP_BASE_URL,
        REACT_APP_VERSION: parsedEnvs.REACT_APP_VERSION,
        REACT_APP_LOGO_URL: parsedEnvs.REACT_APP_LOGO_URL,
        REACT_APP_ORG_NAME: parsedEnvs.REACT_APP_ORG_NAME,
        REACT_APP_SENTRY_DSN: parsedEnvs.REACT_APP_SENTRY_DSN,
        REACT_APP_WEBSOCKET_CLIENT_URL: parsedEnvs.REACT_APP_WEBSOCKET_CLIENT_URL
    }
}