export function getQueryObject(query: object) {
    const queryEntries = Object.entries(query)
    const params = queryEntries[0][0]
    const value = queryEntries[0][1]
    return { params, value }
}


export function getCleanedClientId(clientId: string){
    return clientId.replace(/\//g, "_")
}