export const RELATIVE_PATHS = {
    invoices: "/invoices",
    clients: "/clients",
    audits: "/audits",
    newInvoices: "/taskinvoices",
    users: "/users",
    priorityTasks: "priorityTasks",
    taskConfiguration: "/taskConfiguration",
    qrCodes: "qrcodes",
    personalToDoList: "/personalToDoList",
    inventory: "/inventory",
    searchSubTasks: "/searchSubTasks",
    configuration: "/organization",
    clientInventory: "/clientInventory",
    taskAutomation: "automatedTasks",
    aggregateAudits: "/admin/reports/aggregateAudits",
    taskClientAssociationRelativePath: "/taskClientAssociations",
    taskResources:"/tasks",
    taskPeriodicity:"/taskPeriodicity",
    completedTasks:"/completedTasks",
    subTasks:"/subTasks",
    snoozedTasks:"/snoozedTasks",
    hsnSacDetails:"/hsnSacDetails",
    taskinvoices:"/taskinvoices",
    leave:"/employeeLeave",
    applicableHolidays:"/applicableHolidays",
    orgLeave:"/orgLeave",
    workLogList:"/workLogList",
    workLog:"workLog",
    dashboard:"/reports/primaryTaskList"
}

export const CONFIG_KEYS = {
    taskCategory:"taskCategory",
    userDesignations:"userDesignations"
}
