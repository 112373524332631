import CssBaseline from '@mui/material/CssBaseline';
import * as React from "react";
import ReactDOM from "react-dom/client";
import toast, { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from "react-router-dom";
import { Toaster as WebSocketToaster } from "sonner"
import { ApiContextProvider } from './context/apiStore';
import SuspenseWrapper from './SuspenseWrapper';

const App = React.lazy(() => import("./App"))


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      onError: (error: any) => {
        // toast.error(error?.response?.data.message || "Something went wrong")
      },
    },
    mutations: {
      onError: (error: any) => {
        toast.error(error?.response?.data.message || "Something went wrong")
      },
    },
  }
});



const Main = () => {
  const getEnvStoreQuery = useQuery("getEnvStore", async () => {
    const protocol = window.location.protocol;
    let url;
    if (window.location.hostname !== 'localhost') {
      url=`${protocol}//${window.location.hostname}/v1/org/env?domainName=${window.location.hostname}`
    } else {
      if(process.env.REACT_APP_BASE_DOMAIN === 'localhost')
        url = `${protocol}//${process.env.REACT_APP_BASE_DOMAIN}:8000/v1/org/env?domainName=${window.location.hostname}`
      else
      {
        return {
          "IDENTITY_POOL_ID": "us-east-1:2fa2a71e-d391-464f-b7e5-392cd9354ce8",
          "COGNITO_POOL_ID": "us-east-1_iGECDfRmW",
          "WEB_CLIENT_ID": "506bh5p8ku6618umo9j1jon7ip",
          "AWS_REGION": "us-east-1",
          "S3_BUCKET_NAME": "n-oms-organization-config",
          "REACT_APP_CLIENT_PORTAL_API_URL": "https://api.dev-clientportal.n-oms.in",
          "REACT_APP_WEBSOCKET_CLIENT_URL": "wss://09wzbabad1.execute-api.us-east-1.amazonaws.com/dev",
          "REACT_APP_SENTRY_DSN": "https://4689560e8d9bc16a7b45626ea25d8d41@o4506517126184960.ingest.sentry.io/4506517128871936",
          "REACT_APP_LOGO_URL": "https://n-oms-users.s3.amazonaws.com/sekhar-and-jayathi-logo.png",
          "REACT_APP_ORG_NAME": "Sekhar and Jayathi"
          }
       }
    }
    const response = await fetch(url);
    return await response.json()
  }, {
    onSuccess(data) {
      sessionStorage.setItem("n-oms-env-store", JSON.stringify(data))
    },
  })

  return (
    <React.StrictMode>
      <ApiContextProvider>
        <BrowserRouter>
          <CssBaseline />
          {getEnvStoreQuery.isLoading ? "Loading..." : (
             <SuspenseWrapper element={<App />}/>)}
          <ReactQueryDevtools />
        </BrowserRouter>
      </ApiContextProvider>
      <Toaster toastOptions={{ style: { backgroundColor: "#001f3f", color: "white" } }} />
      <WebSocketToaster />
    </React.StrictMode>

  )
}

ReactDOM.createRoot(document.querySelector("#root") as any).render(
  <QueryClientProvider client={queryClient}>
    <Main />
  </QueryClientProvider>

);
